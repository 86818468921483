// Generated by Framer (9bf39e5)

import { addFonts, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["b6mj3e2Q3"];

const variantClassNames = {b6mj3e2Q3: "framer-v-1r39cth"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "b6mj3e2Q3", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "b6mj3e2Q3", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-xZzuL", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1r39cth", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"b6mj3e2Q3"} ref={ref} style={{backgroundColor: "rgb(0, 0, 0)", ...style}} transition={transition}><motion.div className={"framer-1pn9jn7"} data-framer-name={"High Contrast Screen"} layoutDependency={layoutDependency} layoutId={"HSBkwpzu9"} style={{backgroundColor: "rgb(255, 255, 255)", filter: "contrast(50)", WebkitFilter: "contrast(50)"}} transition={transition}><motion.div className={"framer-sw8mok"} data-framer-name={"Blurred Object"} layoutDependency={layoutDependency} layoutId={"lT_P_sye0"} style={{backgroundColor: "rgb(0, 0, 0)", borderBottomLeftRadius: "50%", borderBottomRightRadius: "50%", borderTopLeftRadius: "50%", borderTopRightRadius: "50%", filter: "blur(16px)", WebkitFilter: "blur(16px)"}} transition={transition}/><motion.div className={"framer-1e3l4nd"} data-framer-name={"Blurred Object"} layoutDependency={layoutDependency} layoutId={"ED1xjx9xo"} style={{background: "linear-gradient(180deg, rgb(255, 255, 255) 0%, hsl(0, 100%, 96%) 100%)", borderBottomLeftRadius: "50%", borderBottomRightRadius: "50%", borderTopLeftRadius: "50%", borderTopRightRadius: "50%", filter: "blur(16px)", WebkitFilter: "blur(16px)"}} transition={transition}/><motion.div className={"framer-e1qv4v"} data-framer-name={"Blurred Object"} layoutDependency={layoutDependency} layoutId={"e_6DwmQli"} style={{background: "linear-gradient(225deg, hsl(216, 17%, 34%) 0%, rgb(0, 0, 0) 100%)", borderBottomLeftRadius: "50%", borderBottomRightRadius: "50%", borderTopLeftRadius: "50%", borderTopRightRadius: "50%", filter: "blur(16px)", WebkitFilter: "blur(16px)"}} transition={transition}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-xZzuL [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-xZzuL * { box-sizing: border-box; }", ".framer-xZzuL .framer-1li5f3h { display: block; }", ".framer-xZzuL .framer-1r39cth { height: 520px; overflow: visible; position: relative; width: 520px; }", ".framer-xZzuL .framer-1pn9jn7 { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 520px); left: 0px; mix-blend-mode: screen; overflow: visible; position: absolute; right: 0px; top: 0px; z-index: 1; }", ".framer-xZzuL .framer-sw8mok { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 205px); left: 106px; overflow: hidden; position: absolute; top: 80px; width: 205px; will-change: transform; }", ".framer-xZzuL .framer-1e3l4nd { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 176px); left: 106px; overflow: hidden; position: absolute; top: calc(54.807692307692335% - 176px / 2); width: 176px; will-change: transform; }", ".framer-xZzuL .framer-e1qv4v { aspect-ratio: 1 / 1; bottom: 81px; flex: none; height: var(--framer-aspect-ratio-supported, 205px); left: 209px; overflow: hidden; position: absolute; width: 205px; will-change: transform; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 520
 * @framerIntrinsicWidth 520
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerRx2V0APGc: React.ComponentType<Props> = withCSS(Component, css, "framer-xZzuL") as typeof Component;
export default FramerRx2V0APGc;

FramerRx2V0APGc.displayName = "Liquid Effect";

FramerRx2V0APGc.defaultProps = {height: 520, width: 520};

addFonts(FramerRx2V0APGc, [])